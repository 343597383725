import styled from 'styled-components'

export const ProgressDot = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => (props.done ? '#FF5E6D' : '#E5E5E5')};
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 18px;
    height: auto;
  }
`

export const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;

  & .RSPBstep:first-child {
    transform: translateX(0%) !important;
  }

  @media (max-width: 1100px) {
    width: 85%;
    margin-left: 20px;
  }
`

export const EventStatusStep = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const EventStatusStepTitle = styled.span`
  position: absolute;
  bottom: -30px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.done ? '#FF5E6D' : 'black')};
  white-space: nowrap;
  width: 80px;
  text-align: center;
`
