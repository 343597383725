import React, { useMemo } from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'
import 'react-step-progress-bar/styles.css'
import {
  EventStatusStep,
  EventStatusStepTitle,
  ProgressDot,
  Wrapper
} from './EventStatusProgressBar.styles'
import reviewingImg from '../../assets/userEvent/reviewing.svg'
import liveImg from '../../assets/userEvent/live.svg'
import endedImg from '../../assets/userEvent/ended.svg'
import pickWinnerImg from '../../assets/userEvent/pickWinner.svg'
import moment from 'moment'

export const EventStatusProgressBar = ({ status, expiresAt, featuredAt }) => {
  const percents = useMemo(() => {
    const isEnded = expiresAt?.iso && moment(expiresAt?.iso).isBefore(moment())

    const in24h = moment(expiresAt?.iso).add(24, 'hours')

    if (isEnded) {
      return 100
    }

    if (moment(featuredAt).isAfter(moment()) && status === 'active') {
      return 29
    }

    switch (status) {
      case 'submitted':
      case 'reviewing':
        return 20
      case 'scheduled':
        return 29
      case 'active':
        return 50
      case 'closed':
        return 100
      case 'winnerSelection':
        return 75
      default:
        return 100
    }
  }, [status, featuredAt, expiresAt])

  return (
    <Wrapper>
      <ProgressBar
        percent={percents}
        filledBackground="#FF5E6D"
        unfilledBackground="#E5E5E5"
        height={3}
      >
        <Step transition="scale">
          {({ accomplished }) => (
            <EventStatusStep>
              <ProgressDot done={accomplished}>
                <img
                  style={{
                    filter: `grayscale(${accomplished ? 0 : 100}%)`,
                    marginRight: -2
                  }}
                  src={reviewingImg}
                />
              </ProgressDot>
              <EventStatusStepTitle done={accomplished}>
                Reviewing
              </EventStatusStepTitle>
            </EventStatusStep>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <EventStatusStep>
              <ProgressDot done={accomplished}>
                <img
                  style={{
                    filter: `invert(${accomplished ? 100 : 0}%)`
                  }}
                  src={liveImg}
                />
              </ProgressDot>
              <EventStatusStepTitle done={accomplished}>
                Live
              </EventStatusStepTitle>
            </EventStatusStep>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <EventStatusStep>
              <ProgressDot done={accomplished}>
                <img
                  style={{
                    filter: `invert(${accomplished ? 100 : 0}%)`
                  }}
                  src={endedImg}
                />
              </ProgressDot>
              <EventStatusStepTitle done={accomplished}>
                Ended
              </EventStatusStepTitle>
            </EventStatusStep>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished }) => (
            <EventStatusStep>
              <ProgressDot done={accomplished}>
                <img
                  style={{ filter: `invert(${accomplished ? 100 : 0}%)` }}
                  src={pickWinnerImg}
                />
              </ProgressDot>
              <EventStatusStepTitle done={accomplished}>
                Pick Winner
              </EventStatusStepTitle>
            </EventStatusStep>
          )}
        </Step>
      </ProgressBar>
    </Wrapper>
  )
}
