import React from 'react'
import {
  AltPrimaryButton,
  ButtonsGrid,
  CloseButton,
  ModalOverlay,
  ModalWindow,
  PrimaryButton
} from './EventDetailView.styles'
import AreYouSureImage from '../../assets/myevent/areYouSureIcon.svg'
import crossIcon from '../../assets/cross.svg'

export const AreYouSureWindow = ({ final, onClose, onConfirm }) => {
  return (
    <ModalOverlay>
      <ModalWindow>
        <CloseButton onClick={onClose}>
          <img src={crossIcon} alt="" />
        </CloseButton>
        <img src={AreYouSureImage} alt="" />
        <h3>Are you sure?</h3>
        {!final && (
          <p>
            You can pick <strong>3 winners</strong> in total.
            <br />
            Winners will be finalized in 24 hrs after the event ended, you can
            make changes until then.
          </p>
        )}
        {final && (
          <p>
            Once you confirm, you cannot make any changes to your selections.
          </p>
        )}
        <ButtonsGrid>
          <AltPrimaryButton onClick={final ? onClose : onConfirm}>
            {final ? 'Cancel' : 'Confirm Now'}
          </AltPrimaryButton>
          <PrimaryButton onClick={final ? onConfirm : onClose}>
            {final ? 'Confirm' : 'Select More'}
          </PrimaryButton>
        </ButtonsGrid>
      </ModalWindow>
    </ModalOverlay>
  )
}
