import { useQuery } from 'react-query'
import moment from 'moment'
import { GET_SEARCH_RECOMMENDATION } from '../constants/ApiEndpoints'
import {
  getUserById,
  getUserEventById,
  getUserEventSubmissionsById,
  getMyUserEvents
} from '../redux/apis'

export const useUserEventDetails = eventId =>
  useQuery(
    ['GET_USER_EVENT_DETAILS', eventId],
    async () => {
      const res = await getUserEventById(eventId)
      const author = await getUserById(res?.user?.objectId)

      res.user = author.user

      return res
    },
    { enabled: Boolean(eventId) }
  )

export const useMyUserEventsQuery = statuses =>
  useQuery(
    ['MY_USER_EVENTS', ...statuses],
    async () => {
      const { userEvents } = await getMyUserEvents({
        statuses
      })

      if (!userEvents) return []

      userEvents.forEach(event => {
        event.customStatus = event.status
        switch (event.status) {
          case 'active':
            if (moment(event.featuredAt?.iso).isAfter(moment())) {
              event.customStatus = 'scheduled'
              return
            }
            if (moment(event.expiresAt?.iso).isBefore(moment())) {
              event.customStatus = 'winnerSelection'
              return
            }
        }
      })

      return userEvents
    },
    {
      staleTime: 5000, // refetch after 5s
      cacheTime: 60000 // store in cache 60sec
    }
  )
