import { ButtonBack, CarouselProvider, DotGroup } from 'pure-react-carousel'
import styled from 'styled-components'

export const EventDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: relative;

  @media (max-width: 1100px) {
    gap: 0;
  }
`

export const Content = styled.div`
  width: 80vw;
  max-width: 1300px;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  padding-top: 50px;
  align-items: stretch;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 100%;
    padding-top: 16px;
  }
`

export const DesignsContainer = styled(Content)`
  flex-direction: column;
  padding-bottom: 20px;
`

export const DesignsGrid = styled.div`
  width: 100%;
  min-height: 300px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 14px;
  position: relative;

  @media (max-width: 1100px) {
    grid-template-columns: ${props =>
      props.designs ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
    padding: 0 10px;
    gap: 8px;
    box-sizing: border-box;
    min-height: 110px;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const LeftPart = styled.div`
  width: 560px;

  @media (max-width: 1100px) {
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const RightPart = styled.div`
  flex: 1;
  margin-left: 60px;
  box-sizing: border-box;
  background: #f9f9f9;
  padding: 29px 24px;
  border-radius: 16px;

  & p {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
  }

  @media (max-width: 1100px) {
    background: transparent;
    margin-left: 0;
    margin-top: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 1100px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`

export const PreviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  & h2 {
    margin: 0;
  }
`

export const Countdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0f0d22;
  margin-top: 10px;

  & img {
    width: 16px;
    height: 16px;
  }

  & span {
    margin-left: 6px;
  }
`

export const Subheader = styled.h3`
  margin: 0;
  margin-bottom: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
`

export const RequirementTag = styled.div`
  background: #faecec;
  border-radius: 100px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #000000;
  height: 40px;
  box-sizing: border-box;
  background: ${props => props.background};
`

export const TagsGrid = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

export const HashtagTag = styled.a`
  background: #ffffff;
  border: 1px solid #f3c1be;
  border-radius: 87px;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  cursor: pointer;
`

export const RewardLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #000000;

  & > div {
    width: 24px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Rewards = styled.div`
  margin-bottom: 25px;
`

export const SlideImage = styled.img`
  width: 560px;
  height: 560px;
  border-radius: 16px;

  @media (max-width: 1100px) {
    width: 100%;
    height: 100%;
  }
`

export const Dots = styled(DotGroup)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;

  & button {
    box-sizing: border-box;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: none;
    flex-shrink: 0;
    flex-grow: 0;
    background: #e5e5e5;
    padding: 0;
    margin: 0;

    &:disabled {
      background: #4f4f4f;
    }
  }
`

export const PrevButton = styled(ButtonBack)`
  position: absolute;
  top: calc(48% - 20px);
  left: 12px;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    display: none;
  }

  & img {
    transform: rotate(90deg);
    height: 18px;
  }
`

export const NextButton = styled(PrevButton)`
  left: unset;
  right: 12px;

  & img {
    transform: rotate(-90deg);
    height: 18px;
  }
`

export const CarouselWrapper = styled(CarouselProvider)`
  position: relative;
`

export const JoinedLabel = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  height: 36px;
  width: 138px;
  right: 0;
  bottom: 24px;
  border-radius: 18px 0 0 18px;
  z-index: 100;
  font-family: Helvetica Neue;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  & span {
    margin-left: 3px;
  }

  & > div {
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: -6px;
    background: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  & img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: -4px;
    z-index: 1;
  }
`

export const EventProgressContainer = styled.div`
  margin-top: 20px;

  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`

export const DesignsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`

export const DesignsTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;

  @media (max-width: 1000px) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
`

export const BigActionButton = styled.button`
  width: 495px;
  height: 45px;
  background: ${props => (props.disabled ? '#E5E5E5' : '#ff5e6d')};
  border-radius: 93px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${props => (props.disabled ? 'black' : '#ffffff')};
  flex-shrink: 0;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  z-index: 999;
  position: fixed;
  bottom: 60px;

  &:hover {
    background: ${props => (props.disabled ? '#E5E5E5' : '#d94e5b')};
  }

  @media (max-width: 1100px) {
    width: 95%;
    box-sizing: border-box;
    margin: 0 auto;
    margin-bottom: -50px;
  }
`

export const ImageTag = styled.div`
  position: absolute;
  width: 130px;
  height: 40px;
  left: 0;
  top: 40px;
  background: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`

export const BackButtonWrapper = styled.div`
  position: fixed;
  top: 90px;
  left: 32px;

  @media (max-width: 1100px) {
    display: none;
  }
`

export const LevelLimitationOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;

  & img {
    margin-bottom: 16px;
    height: 53px;
  }
`

export const WinnerSelectionCard = styled.div`
  background: #e5e5e5;
  border: 1px dashed #979797;
  border-radius: 10px;
  flex: 1;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    & img {
      max-width: 60%;
      max-height: 60%;
    }
  }
`

export const WinBadge = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 50px;
  height: 50px;

  @media (max-width: 800px) {
    z-index: 10;
    top: 2px;
    right: 2px;
    width: ${({ isSelectedSection }) => (isSelectedSection ? '24px' : '40px')};
    height: ${({ isSelectedSection }) => (isSelectedSection ? '24px' : '40px')};
  }
`

export const WinnerSelectedCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;

  .show-on-hover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(0, 0, 0, 0.5) 0.01%,
      rgba(121, 121, 121, 0) 47.4%,
      rgba(0, 0, 0, 0.38) 100%
    );
  }

  &:hover .show-on-hover {
    display: block;
  }

  ${props =>
    !props.winnersMode &&
    `
  @media (max-width: 800px) {
    max-width: 50vw;
    min-width: 40vw;
  }
  `}
`

export const BottomRight = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

export const WinnerSelectedImage = styled.img`
  width: 100%;
  height: 100%;
`

export const WinnerSelectedProfile = styled.div`
  position: absolute;
  top: 17px;
  left: 17px;
  display: flex;
  align-items: center;
  color: white;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-weight: 700;

  @media (max-width: 1100px) {
    top: 4px;
    left: 4px;
    font-size: 12px;

    display: ${({ isSelectedSection }) =>
      isSelectedSection ? 'none' : 'flex'};
  }
`

export const WinnerSelectedUserPic = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
  background: url(f43bbe8a40129140f2a5f68a7b5d6fe2_Image.jpg), #ffffff;
  border: 2px solid #f5f5f5;
  margin-right: 8px;

  @media (max-width: 1100px) {
    width: 27px;
    height: 27px;
    margin-right: 3px;
    border-width: 1px;
  }
`

export const ActionButton = styled.button`
  background: ${props => (props.active ? '#ff5e6d' : '#FFFFFF')};
  width: 36px;
  height: 36px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  right: 14px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  color: ${props => (props.active ? '#FFFFFF' : '#8b8b8b')};

  &:hover {
    background: #ff5e6d;
    color: white;
  }

  @media (max-width: 800px) {
    top: 6px;
    right: 6px;
    width: 30px;
    height: 30px;
  }
`

export const ModalOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalWindow = styled.div`
  width: 530px;
  height: 500px;
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  text-align: center;

  & h3 {
    margin-bottom: 0;
    margin-top: 18px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  & p {
    margin-top: 18px;
    text-aling: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 368px;
  }
`

export const PreviewWindow = styled(ModalWindow)`
  width: 720px;
  height: 781px;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    width: calc(100vw - 44px);
    max-height: min(472px, 75vh);
    height: auto;
    max-width: 330px;
    padding: 0;
    padding: 16px 0 26px;
  }
`

export const ButtonsGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
`

export const PrimaryButton = styled.button`
  background: #ff5e6d;
  border-radius: 30px;
  width: 160px;
  height: 42px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background: #ff4a5b;
  }

  @media (max-width: 800px) {
    height: 38px;
    width: 180px;
    border-radius: 22px;
    font-size: 14px;
    margin-top: 17px;
  }
`

export const CloseButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  filter: invert(1);
  opacity: 0.5;
  cursor: pointer;

  @media (max-width: 800px) {
    top: 25px;
    right: 15px;
  }

  & img {
    width: 16px;
    height: 16px;

    @media (max-width: 800px) {
      width: 14px;
      height: 14px;
    }
  }
`

export const AltPrimaryButton = styled(PrimaryButton)`
  background: transparent;
  border: 2px solid #ff5e6d;
  color: #ff5e6d;
  flex-shrink: 0;

  &:hover {
    color: white;
    background: #ff5e6d;
  }
`

export const PreviewBody = styled.div`
  padding: 45px 70px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 0;
  }
`

export const PreviewUserProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;

  @media (max-width: 800px) {
    padding: 0 15px 13px;
    margin-bottom: 0;
  }
`

export const PreviewAvatar = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;

  @media (max-width: 800px) {
    width: 32px;
    height: 32px;
  }
`

export const PreviewUsername = styled.span`
  cursor: pointer;
  margin-left: 7px;
  margin-right: 22px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #000000;

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 17px;
    margin-right: 7px;
  }
`

export const CanvasSizer = styled.div`
  width: 583px;
  height: 583px;

  @media (max-width: 800px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`

export const FollowButton = styled.button`
  background: transparent;
  border: none;
  text-decoration: underline;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #ff5e6d;
  cursor: pointer;

  @media (max-width: 800px) {
    font-size: 14px;
  }
`

export const HostSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & img {
    width: 35px;
    height: 35px;
    border: 2px solid #f1f1f1;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 2px;
  }

  & span {
    width: 45px;
    height: 17px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #ff5e6d;
    border-radius: 10px;
  }
`
