import React, { useEffect, useState, useRef } from 'react'
import {
  ActionButton,
  WinBadge,
  WinnerSelectedCard,
  WinnerSelectedImage,
  WinnerSelectedProfile,
  WinnerSelectionCard,
  WinnerSelectedUserPic,
  WinnerHoverContainer,
  BottomRight
} from './EventDetailView.styles'
import { useSelector, useDispatch } from 'react-redux'
import WinnerIcon from '../../assets/winnerIcon.svg'
import {
  setFavorite,
  setUnfavorite,
  quickSetFavorite,
  quickSetUnfavorite,
  setLike,
  setUnlike,
  quickSetLike,
  quickSetUnlike
} from '../../redux/designerReducers/userSavedReducer'
import { ReactComponent as CheckmarkIcon } from '../../assets/myevent/checkmark.svg'
import { ReactComponent as DeselectIcon } from '../../assets/myevent/deselect.svg'
import {
  DMLikeCardButton,
  DMSaveCardButton
} from '../DesignerTheme/DMCardButton'
import winBadgeIcon from '../../assets/userEvent/winBadge.svg'

const EmptyCard = () => {
  return (
    <WinnerSelectionCard>
      <img src={WinnerIcon} alt="" />
    </WinnerSelectionCard>
  )
}

export const DesignSubmissionCard = ({
  data,
  selected,
  remove,
  winners,
  defaultSelected,
  onToggleSelected,
  closed,
  onPreview,
  isEditWinnersMode,
  isSelectedSection,
  refFn
}) => {
  const [image, setImage] = useState()
  const [saved, setSaved] = useState(false)
  const [liked, setLiked] = useState(false)
  const { savedDesigns, savedUserInspirations, savedLikes } = useSelector(
    state => state.userSaved
  )
  const dispatch = useDispatch()
  const [likeDiff, setLikeDiff] = useState(0)
  const initialLiked = useRef(undefined)

  useEffect(() => {
    if (!data.objectId) {
      return
    }
    setLikeDiff(0)
    if (savedLikes[data.objectId]) {
      setLiked(true)
      if (initialLiked.current === false) {
        setLikeDiff(1)
      }
      if (initialLiked.current === undefined) {
        initialLiked.current = true
      }
    } else {
      setLiked(false)
      if (initialLiked.current === true) {
        setLikeDiff(-1)
      }
      if (initialLiked.current === undefined) {
        initialLiked.current = false
      }
    }
  }, [data, savedLikes])

  useEffect(() => {
    if (!data.objectId) {
      return
    }

    if (savedDesigns[data.objectId]) {
      setSaved(true)
    } else {
      setSaved(false)
    }
  }, [savedDesigns, data])

  useEffect(() => {
    if (data.idea && data.idea.thumbImageFile)
      setImage(data.idea.thumbImageFile.url)
    if (data.idea && data.idea.cfThumbImageUrl)
      setImage(data.idea.cfThumbImageUrl)
  }, [data])

  const handleLike = e => {
    e.stopPropagation()
    if (!data.objectId) return
    if (savedLikes[data.objectId]) {
      dispatch(quickSetUnlike(data.objectId))
      dispatch(setUnlike(data.objectId))
    } else {
      dispatch(quickSetLike(data.objectId))
      dispatch(setLike(data.objectId))
    }
  }

  const handleSave = e => {
    e.stopPropagation()
    if (!data.objectId) return
    if (savedDesigns[data.objectId]) {
      dispatch(quickSetUnfavorite(data.objectId))
      dispatch(setUnfavorite(data.objectId))
    } else {
      dispatch(quickSetFavorite(data.objectId))
      dispatch(setFavorite(data.objectId))
    }
  }

  const isSelected =
    selected || winners.find(winner => winner.objectId === data.objectId)

  const userPic =
    data?.user?.funAvatarImageUrl ||
    data?.user?.thumbProfileImageFile?.url ||
    'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'

  return (
    <WinnerSelectedCard ref={refFn} winnersMode={winners} winner={remove}>
      <WinnerSelectedImage onClick={onPreview} src={image} alt="" />
      <div class="show-on-hover" onClick={onPreview}>
        <BottomRight>
          <DMLikeCardButton
            active={liked}
            number={(data?.numLikes || 0) + likeDiff}
            onClick={handleLike}
          />
          <DMSaveCardButton active={saved} onClick={handleSave} />
        </BottomRight>
      </div>
      {!closed && (
        <WinnerSelectedProfile isSelectedSection={isSelectedSection}>
          <WinnerSelectedUserPic src={userPic} />
          {data.user.uniqueDisplayName}
        </WinnerSelectedProfile>
      )}
      {isEditWinnersMode && !closed && !remove && (
        <ActionButton
          active={isSelected}
          onClick={() => onToggleSelected(data)}
        >
          <CheckmarkIcon />
        </ActionButton>
      )}
      {isEditWinnersMode && !closed && remove && (
        <ActionButton
          active={isSelected}
          onClick={() => onToggleSelected(data)}
        >
          <DeselectIcon />
        </ActionButton>
      )}

      {!isEditWinnersMode && remove && defaultSelected && (
        <WinBadge
          src={winBadgeIcon}
          alt=""
          isSelectedSection={isSelectedSection}
        />
      )}
    </WinnerSelectedCard>
  )
}

export const WinnersSection = ({
  defaultWinners,
  winners,
  onToggleSelected,
  closed,
  isEditWinnersMode
}) => {
  const emptyArray = Array(3).fill('')
  const winnersSlots =
    winners.length === 0 ? emptyArray : [...winners, ...emptyArray].slice(0, 3)

  return (
    <>
      {winnersSlots.map((winner, i) =>
        winner ? (
          <DesignSubmissionCard
            isEditWinnersMode={isEditWinnersMode}
            key={winner.objectId || i}
            remove={true}
            defaultSelected={defaultWinners?.length > 0}
            data={winner}
            closed={closed}
            winners={winners}
            onToggleSelected={onToggleSelected}
            isSelectedSection
          />
        ) : (
          <EmptyCard key={i} />
        )
      )}
    </>
  )
}
