import React, { useCallback, useMemo } from 'react'
import { AltPrimaryButton, ButtonsGrid, CanvasSizer, CloseButton, FollowButton, ModalOverlay, ModalWindow, PreviewAvatar, PreviewBody, PreviewUsername, PreviewUserProfile, PreviewWindow, PrimaryButton } from './EventDetailView.styles'
import AreYouSureImage from '../../assets/myevent/areYouSureIcon.svg'
import crossIcon from '../../assets/cross.svg'
//import DesignHeader from '../DesignDetail/DesignHeader'
import DesignCanvas from '../DesignCanvas'
import { useDispatch, useSelector } from 'react-redux'
import { FollowAction, unFollowAction } from '../../redux/actions/FollowingActions'
import Link from 'next/link'

export const PreviewPostWindow = ({ winners, onToggle, final, post, onClose, isEditWinnersMode }) => {
  const dispatch = useDispatch()
  const { following } = useSelector(state => state.follows)
  const isSelected = winners.includes(post.objectId)
  const followState = useMemo(() => {
    return following && following.includes(post?.user?.objectId) ? true : false
  }, [following, post?.user?.objectId])

  const designerId = post?.user?.objectId

  const followClick = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      if (followState) {
        dispatch(unFollowAction(designerId))
      } else {
        dispatch(FollowAction(designerId))
      }
    },
    [followState, designerId, dispatch]
  )

  return (
    <ModalOverlay>
      <PreviewWindow>
        <CloseButton onClick={onClose}>
          <img src={crossIcon} alt="" />
        </CloseButton>
        <PreviewBody>
          <PreviewUserProfile>
            <Link href={`/dm/${post?.user?.objectId}`}>
              <PreviewAvatar src={post?.user?.funAvatarImageUrl || post?.user?.thumbProfileImageFile?.url || 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'} />
            </Link>
            <Link href={`/dm/${post?.user?.objectId}`}>
              <PreviewUsername>{post?.user?.uniqueDisplayName}</PreviewUsername>
            </Link>
            <FollowButton onClick={followClick}>{followState ? 'Unfollow' : 'Follow'}</FollowButton>
          </PreviewUserProfile>
          <CanvasSizer>
            <DesignCanvas scalable={true} data={post} />
          </CanvasSizer>
        </PreviewBody>
        {isEditWinnersMode && (isSelected ? <AltPrimaryButton onClick={onToggle}>Deselect</AltPrimaryButton> : <PrimaryButton onClick={onToggle}>Select</PrimaryButton>)}
      </PreviewWindow>
    </ModalOverlay>
  )
}
